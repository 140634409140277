import {
    FETCH_SERVICE_NAV_SUCCESS,
    FETCH_SERVICE_ARBEITSWERTE_SUCCESS,
    SET_SERVICE_NAV_FILTER_VALUES
} from '../../../actionTypes';
import { apiClient } from '../../../../helpers/apiHelperV2';
import { shouldFetch } from '../../../fetchStatus';
import { standortUrlSelector } from '../../../selectors/url';
import { navigatorResponseSelector } from '../../../selectors/standort/service/serviceNavigator';

export const fetchServiceArbeitswerte = (serviceArbeitswerte) => {
    return {
        type: FETCH_SERVICE_ARBEITSWERTE_SUCCESS,
        payload: { foundArbeitswerteServices: serviceArbeitswerte.foundArbeitswerteServices, fromSelectedStandort: serviceArbeitswerte.fromSelectedStandort }
    }
}

export const fetchServiceCategoryIfNeeded = () => {
    return async function (dispatch, getState) {
        const state = getState();

        const standortUrl = standortUrlSelector(state);
        const serviceResponse = navigatorResponseSelector(state);

        if (shouldFetch(serviceResponse.status)) {
            dispatch(fetchServiceNavigator({}, {}));
            try {
                const res = await apiClient.get('/api/v1/bGetAllServicesCategory', {
                    params: {
                        standortUrl,
                    },
                });
                const resData = await apiClient.get('/api/v1/bGetAllServices', {
                    params: {
                        standortUrl,
                    },
                });

                dispatch(fetchServiceNavigator(res.data, resData.data));
            } catch (error) {
                dispatch(fetchServiceNavigator({}, {}));
            }
        }
    };
};

export const fetchServiceArbeiteCategoryIfNeeded = () => {
    return async function (dispatch, getState) {
        const state = getState();

        const standortUrl = standortUrlSelector(state);
        const serviceResponse = navigatorResponseSelector(state);

        if (shouldFetch(serviceResponse.status)) {
            dispatch(fetchServiceArbeitswerte({}));
            try {
                const resArbeitswerte = await apiClient.get('/api/v1/bGetAllArbeitswerteServices', {
                    params: {
                        standortUrl,
                    },
                });

                dispatch(fetchServiceArbeitswerte(resArbeitswerte.data));
            } catch (error) {
                dispatch(fetchServiceArbeitswerte({}));
            }
        }
    };
};

export const fetchAllMobileServicesIfNeeded = () => {
    return async function (dispatch, getState) {
        const state = getState();

        const standortUrl = standortUrlSelector(state);
        const serviceResponse = navigatorResponseSelector(state);

        if (shouldFetch(serviceResponse.status)) {
            dispatch(setServiceNavigatorResMobile({}));
            try {
                const resMobile = await apiClient.get('/api/v1/bGetAllMobileServices', {
                    params: {
                        standortUrl,
                    },
                });

                dispatch(setServiceNavigatorResMobile(resMobile.data?.foundMobileServices));
            } catch (error) {
                dispatch(setServiceNavigatorResMobile({}));
            }
        }
    };
};
export const fetchAdditionalProductsIfNeeded = () => {
    return async function (dispatch, getState) {
        const state = getState();

        const standortUrl = standortUrlSelector(state);
        const serviceResponse = navigatorResponseSelector(state);

        if (shouldFetch(serviceResponse.status)) {
            dispatch(setAdditionalProducts({}));
            try {
                const resProducts = await apiClient.get('/api/v1/bGetAdditionalProducts', {
                    params: {
                        standortUrl,
                    },
                });

                dispatch(setAdditionalProducts(resProducts.data?.additionalProduts));
            } catch (error) {
                dispatch(setAdditionalProducts({}));
            }
        }
    };
};

export const fetchServiceNavigator = (ServicesCategory, Services) => {
    return {
        type: FETCH_SERVICE_NAV_SUCCESS,
        payload: { ServicesCategory: ServicesCategory, Services: Services }
    }
};

export const setServiceNavigatorCalculation = (calculation) => {
    return {
        type: SET_SERVICE_NAV_FILTER_VALUES,
        payload: { RepairCalculation: calculation }
    }
};

export const setServiceNavigatorResMobile = (resMobile) => {
    return {
        type: SET_SERVICE_NAV_FILTER_VALUES,
        payload: { ResMobile: resMobile }
    }
};
export const setAdditionalProducts = (products) => {
    return {
        type: SET_SERVICE_NAV_FILTER_VALUES,
        payload: { Products: products }
    }
};

export const setServiceNavigatorCurrentService = (currentServiceCat, service) => {
    return {
        type: SET_SERVICE_NAV_FILTER_VALUES,
        payload: { CurrentServiceCat: currentServiceCat, CurrentService: service }
    }
};

export const setServiceNavigatorNebenFabrikat = (nebenFabrikat) => {
    return {
        type: SET_SERVICE_NAV_FILTER_VALUES,
        payload: { NebenFabrikat: nebenFabrikat }
    }
};

export const setServiceNavigatorCurrentButton = (currentButton) => {
    return {
        type: SET_SERVICE_NAV_FILTER_VALUES,
        payload: { CurrentButton: currentButton }
    }
};

export const setServiceNavigatorVehiclePreSelected = (vehiclePreSelected) => {
    return {
        type: SET_SERVICE_NAV_FILTER_VALUES,
        payload: { VehiclePreSelected: vehiclePreSelected }
    }
};

export const setServiceNavigatorCurrentStep = (currentStep) => {
    return {
        type: SET_SERVICE_NAV_FILTER_VALUES,
        payload: { CurrentStep: currentStep }
    }
};

export const setServiceNavigatorVehicleBrand = (vehicleBrand) => {
    return {
        type: SET_SERVICE_NAV_FILTER_VALUES,
        payload: { vehicleBrand: vehicleBrand }
    }
};

export const setServiceNavigatorVDetail = (vDetail) => {
    return {
        type: SET_SERVICE_NAV_FILTER_VALUES,
        payload: { vDetail: vDetail }
    }
};

export const setServiceNavigatorvDetailFin = (vDetailFin) => {
    return {
        type: SET_SERVICE_NAV_FILTER_VALUES,
        payload: { vDetailFin: vDetailFin }
    }
};

export const setServiceNavigatordrivenDistance = (drivenDistance) => {
    return {
        type: SET_SERVICE_NAV_FILTER_VALUES,
        payload: { drivenDistance: drivenDistance }
    }
};

export const setServiceNavigatorfirstPermit = (firstPermit) => {
    return {
        type: SET_SERVICE_NAV_FILTER_VALUES,
        payload: { firstPermit: firstPermit }
    }
};

export const setServiceNavigatorPostleitzahlSelect = (PostleitzahlSelect) => {
    return {
        type: SET_SERVICE_NAV_FILTER_VALUES,
        payload: { PostleitzahlSelect: PostleitzahlSelect }
    }
};

export const setServiceNavigatorStatus = (status) => {
    return {
        type: SET_SERVICE_NAV_FILTER_VALUES,
        payload: { status: status }
    }
};

import { createSelector } from 'reselect';
import { defaultStateSelector, standortStateSelector } from '../../standort';
import { pendingResponse } from '../../../fetchStatus';

export const navigatorResponseSelector = createSelector(
    [standortStateSelector],
    standortState => standortState.serviceNavigator?.data || pendingResponse
);

export const servicenavigatorResponseSelector = createSelector(
    [defaultStateSelector],
    standortState => standortState.serviceNavigator?.data || pendingResponse
);

export const servicenvigatorStatusSelector = createSelector(
    [servicenavigatorResponseSelector],
    servicenvigatorStatus => servicenvigatorStatus.status
);
import { FETCH_ADD_COUPON_HEIGHT_SUCCESS } from '../actionTypes';

export const saveCouponBannerHeight = (couponHeight) => {
    return async function (dispatch, getState) {
    dispatch({
        type: FETCH_ADD_COUPON_HEIGHT_SUCCESS,
        payload: {couponHeight},
    });
    }
};
